'use strict';

var Brand = {};
var $ = require('jquery');

$(document).ready(function () {
    var marks = $('body').data('brand-marks');

    if (marks === undefined) {
        marks = {};
    }

    $.each(marks, function (i, v) {
        Brand[i] = marks[i];
    });
});

module.exports = Brand;
