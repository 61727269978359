'use strict';
const { renderBuilder } = require('src/common/helpers/handlebars');
const $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');
Backbone.$ = $;

var Modal = require('./modal'),
    layout = renderBuilder(require('src/backbone/templates/modalLayout.js').default),
    footer = renderBuilder(require('src/backbone/templates/modalFooter.js').default),
    loading = require('../../common/loading');

var CommonLayoutModal = Modal.extend(
    /** @lends Modal.prototype */ {
        defaults: {},
        options: null,
        loading: null,
        _initialized: false,
        _lateInitialize: function () {
            if (!this._initialized) {
                this.loading = loading(this.$el);

                this._initialized = true;
            }
        },

        title: 'Notice',
        additionalClasses: '', // Additional modal classes, e.g. modal-lg
        hasCancel: false,
        contentWidth: null,

        // default initialize: set properties against this.options object from passed in object
        initialize: function (options) {
            this.setOptions(options);
        },

        setOptions: function (options) {
            var defaults = _.result(this, 'defaults');

            if (options && 'events' in options) {
                throw new Error('Probably unintentionally overriding Backbone.events');
            }

            var merged = _.defaults({}, options, defaults);

            this.options = merged;
        },

        getOption: function (name) {
            if (this.options === null) {
                this.setOptions(); // load defaults
            }

            return this.options[name];
        },

        // default primary action 'Continue'
        getPrimaryAction: function () {
            return {
                buttonClass: 'success',
                text: 'Continue',
            };
        },

        // default no additional actions
        getActions: function () {
            return [];
        },

        // default no cancel action
        getCancelAction: function () {
            return this.hasCancel;
        },

        makeCloseButton: function () {
            return {
                text: 'Close',
                dismiss: true,
                buttonClass: 'default js-cancel-button',
            };
        },

        makeCancelButton: function () {
            return {
                text: 'Cancel',
                dismiss: true,
                buttonClass: 'default js-cancel-button',
            };
        },

        makeDeleteButton: function (trashIconOnSmallScreens) {
            var spec = {
                buttonClass: 'predanger',
                additionalClasses: 'js-delete-modal-action',
                text: 'Delete',
            };

            if (trashIconOnSmallScreens) {
                spec.text = '<span class="hidden-xs">Delete</span>';
                spec.icon = '<i class="fa fa-trash hidden-sm hidden-md hidden-lg"></i>';
            }

            return spec;
        },

        getFooterData: function () {
            function setLoadingText(action) {
                if (!action) {
                    return action;
                }

                if (_.isUndefined(action.loadingText)) {
                    action.loadingText = action.text;
                }
                return action;
            }

            return {
                actions: this.getActions(),
                cancel: this.getCancelAction(),
                primary: setLoadingText(this.getPrimaryAction()),
            };
        },

        getLayoutData: function () {
            // Apply a late getTitle function if there is one.
            this.applyTitle();

            return {
                title: this.title,
                additionalClasses: this.additionalClasses,
                contentWidth: this.contentWidth,
                fixedPanel: Boolean(this.getOption('fixedPanel')).valueOf(),
            };
        },

        getViewData: function () {
            return {};
        },

        render: function () {
            // Hack to avoid having to super call initialize in every descendent modal.
            this._lateInitialize();

            var layoutData = this.getLayoutData();

            this.$el.html(layout(layoutData));
            this.$el.find('.modal-content').append(this.renderFooter());
            this.renderContent(this.$el.find('.modal-body'));
            this.renderFixedPanel(this.$el.find('.modal-fixed-panel'));

            return this;
        },

        forceContentRender: function () {
            this.renderContent(this.$el.find('.modal-body'));

            return this;
        },

        /**
         * Late apply the modal title if there is a getTitle function
         */
        applyTitle: function () {
            if (typeof this.getTitle === 'function') {
                this.title = this.getTitle();
            }
        },

        renderFooter: function () {
            this.$footer = this.$('.modal-footer');

            return this.updateActions();
        },

        updateActions: function () {
            return this.$footer.html(footer(this.getFooterData()));
        },

        renderContent: function ($body) {},

        renderFixedPanel: function ($body) {},
    }
);

module.exports = CommonLayoutModal;
