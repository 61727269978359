import React from 'react';
import Handlebars from 'handlebars';

const hbr = `
<span class="js-inline-spinner"><i class="fa fa-spin fa-refresh"></i></span>
`.trim();
let template = false;
export const getTemplate = () => {
    return Handlebars.compile(hbr);
};
export default (props) => {
    template = template ? template : getTemplate();
    return template(props);
};
