/*add this to your file global $,_,navbar,intercom*/
'use strict';
var $ = require('jquery'),
    _ = require('underscore');

require('blockui');

$(document).on('logout', function () {
    $.blockUI({
        message: '<br />Logging out...<br /><br />',
        baseZ: 999999,
    });
    window.location = '/k1/auth/logout/';
});

//function globalAjaxCursorChange() {
//
//    $("html").bind("ajaxStart", function () {
//        $(this).addClass('busy');
//    }).bind("ajaxStop", function () {
//        $(this).removeClass('busy');
//    });
//}
//
//globalAjaxCursorChange();
