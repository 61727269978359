'use strict';

import _ from 'underscore';
import $ from 'jquery';
import CommonLayoutModal from 'src/backbone/views/commonLayoutModal';
import { storageShowDialog, storageCloseKey, startKey } from 'src/common/localStorage';
import cookie from 'src/common/cookie';
const { renderBuilder } = require('src/common/helpers/handlebars');
const template = renderBuilder(require('src/backbone/templates/subterfugeWarningDialog.js').default);

export default CommonLayoutModal.extend({
    id: 'subterfuge-danger-modal',
    title: 'Subterfuge',
    additionalClasses: 'modal-lg',
    hasCancel: true,

    events: {
        'hidden.bs.modal': 'postCloseAction',
    },

    initialize: function () {
        _.bindAll(this, 'keyPress');
        $(document).bind('keydown', this.keyPress);
    },

    keyPress: function (e) {
        // Escape key
        if (e.keyCode === 27) {
            $('#subterfuge-danger-modal').modal('hide');
        }
    },

    postCloseAction: function () {
        this.clearStorage();
        $(document).unbind('keydown', this.keyPress);
    },

    clearStorage: function () {
        var localData = {
            key: 'subterfuge-' + Date.now(),
        };
        localData[storageShowDialog] = false;
        cookie.removeItem(startKey, '/k1');
        localStorage.setItem(storageCloseKey, JSON.stringify(localData));
    },

    renderContent: function () {
        const token = $('#selectedBusiness').data('token');
        const features = $('body').data('features')[token];
        this.$el.html(
            template({
                data: {
                    id: features.id,
                    token: features.token,
                    name: features.name,
                    type: features.type,
                    country: features.country,
                },
            })
        );
    },
});
