import cookie from 'src/common/cookie';
import subterfugeWarningDialog from 'src/backbone/views/subterfugeWarningDialog';
import { refreshPage, startKey, storageCloseKey, storageShowDialog } from 'src/common/localStorage';

/**
 * Include this file in new entry_points
 *
 * It looks like it does common level subterfuge logic
 *
 */

window.addEventListener('storage', function (event) {
    if (event.key == refreshPage) {
        location.reload();
    }

    if (event.key == storageCloseKey) {
        var local = localStorage.getItem(storageCloseKey);
        if (local) {
            local = JSON.parse(local);
            if (!local[storageShowDialog]) {
                $('#subterfuge-danger-modal').modal('hide');
            }
        }
    }
});

window.addEventListener('load', function () {
    var subterfuge = cookie.getItem(startKey);
    if (subterfuge === 'show-subterfuge-alert') {
        new subterfugeWarningDialog({ parentModal: this }).show();
    }
});
