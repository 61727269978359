'use strict';

/* global require, window, module */
var CommonLayoutModal = require('./commonLayoutModal');
var _ = require('underscore');

module.exports = CommonLayoutModal.extend(
    /** @lends CommonLayoutModal.prototype */ {
        hasCancel: false, // Yukky...
        events: {
            'click .js-primary': 'onPrimaryClick',
            'click .js-cancel-button': 'onCancelClick',
            // Close listener to call the callback...
        },
        id: 'unsaved-changes-modal',

        forceResponsiveViewPort: true,

        title: 'Changes not saved',

        message:
            '<div>' +
            '<p><strong><i class="fa-regular fa-circle-exclamation"></i> Your changes have not been saved.</strong></p>' +
            '<p>Please go back to save your changes or continue without saving</p>' +
            '</div',

        primaryAction: {
            text: 'Go back and save',
            dismiss: true,
            buttonClass: 'primary js-primary',
        },
        closeAction: {
            text: 'Continue without saving',
            dismiss: true,
            buttonClass: 'default js-cancel-button',
        },

        initialize: function (options) {
            this.id = options.id || this.id;
            _.extend(this, options);
        },

        getActions: function () {
            return [this.closeAction];
        },

        getPrimaryAction: function () {
            return this.primaryAction;
        },

        renderContent: function ($body) {
            if (this.message.indexOf('<div') >= 0) {
                $body.html(this.message);
            } else {
                $body.text(this.message);
            }
        },

        onPrimaryClick: function () {
            this.close();
        },
        onCancelClick: function () {
            this.close();
        },
    }
);
