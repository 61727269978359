'use strict';

var MobileDetect = require('mobile-detect');
var md = new MobileDetect(window.navigator.userAgent);

var myFunctions = function MyMobileDetect(userAgent, maxPhoneWidth) {
    this.mobile = function () {
        //mitigates the UserAgent spoofing ipads do in safari as mackintoshes
        return md.mobile() || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    };
    this.tablet = function () {
        //mitigates the UserAgent spoofing ipads do in safari as mackintoshes
        if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
            return 'iPad';
        }
        return md.tablet();
    };

    this.is = function (device) {
        if (device === 'iPad' && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
            return true;
        }
        return md.is(device);
    };

    this.phone = function () {
        return md.phone();
    };
    this.userAgent = function () {
        return md.userAgent();
    };
    this.userAgents = function () {
        return md.userAgents();
    };
    this.os = function () {
        return md.os();
    };
    this.version = function () {
        return md.version();
    };
    this.versionStr = function () {
        return md.versionStr();
    };
    this.match = function () {
        return md.match();
    };
    this.isPhoneSized = function () {
        return md.isPhoneSized();
    };
    this.mobileGrade = function () {
        return md.mobileGrade();
    };
};

// myFunctions.prototype.isPhoneSized = MobileDetect.prototype.isPhoneSized;
// myFunctions.prototype.userAgent = MobileDetect.prototype.userAgent;

module.exports = myFunctions;
