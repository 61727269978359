//global css
import 'src/../../css/third_party/bootstrap/css/bootstrap.min.css';
import 'src/css/k1/bootstrap-customisations.scss';
import 'src/../../css/third_party/outdated-browser/outdatedbrowser.min.css';
import 'src/../../css/third_party/select2/select2.css';
import 'src/css/third_party/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css';

//fancybox: modal boxes
import 'src/css/third_party/fancybox/jquery.fancybox-2.1.4.css';
import 'src/css/k1/print.scss';
import 'src/css/k1/whiteness/jquery-ui-1.8.13.custom.css';

import 'src/css/k1/k1.scss';
import 'src/css/k1/magic.scss';
import 'src/css/k1/responsive_overrides.scss';
import 'src/css/k1/responsive_newcode.scss';

import 'src/common/global_ajax_error_handler';
