'use strict';

var UnsavedChangesModal = require('./../backbone/views/unsavedChangesModal'),
    _ = require('underscore'),
    $ = require('jquery'),
    Backbone = require('backbone');

var privateHandleAbort = function (contextList, abortHandlerOptions) {
    if (
        // Function where you define stuff that must happen before any checks are made. IE event.preventDefault()
        typeof abortHandlerOptions.ifUnsavedPreHookCallback !== 'function' ||
        // Function that ensures the webpage "stays" where the unsaved changes are located
        typeof abortHandlerOptions.onContinueEditingCallback !== 'function' ||
        // Function that allows the navigation to take place because we chose to discard them
        typeof abortHandlerOptions.onDiscardChangesCallback !== 'function' ||
        // Function that allows us to hook in special functionality that must happen even when no unsaved changes are detected...
        typeof abortHandlerOptions.onNoUnsavedChangesCallback !== 'function'
    ) {
        throw new Error(
            'abortHandlerOptions needs: {ifUnsavedPreHookCallback, onContinueEditingCallback, onDiscardChangesCallback, onNoUnsavedChangesCallback}'
        );
    }

    var unsavedChanges = false,
        unsavedContext = null,
        originalDiscardFunction = abortHandlerOptions.onDiscardChangesCallback;

    // Augment the discountChangesCallback to also delete the context from the ContextList (We don't need it anymore).
    abortHandlerOptions.onDiscardChangesCallback = function () {
        if (unsavedContext !== null) {
            delete contextList[unsavedContext.key];
        }

        originalDiscardFunction();
    };

    _.forEach(contextList, function (context) {
        if (unsavedChanges === false && context.isActiveContext() && context.hasUnsaved()) {
            unsavedChanges = true;
            unsavedContext = context;
        }
    });

    if (unsavedChanges) {
        var prehookReturnValue = abortHandlerOptions.ifUnsavedPreHookCallback() || true;

        var closeHandled = false;
        var modal = new UnsavedChangesModal({
            onPrimaryClick: function () {
                closeHandled = true;
                this.close();
                abortHandlerOptions.onContinueEditingCallback(unsavedContext);
            },
            onCancelClick: function () {
                closeHandled = true;
                this.close();
                abortHandlerOptions.onDiscardChangesCallback();
            },
        });

        modal.on('hide', function () {
            if (!closeHandled) {
                // If user dismisses modal via "x" or clicking outside of the modal closedHandled will be false.
                // Thus we need to ensure discardChanges function is called.
                abortHandlerOptions.onDiscardChangesCallback();
            }
        });

        modal.show();
        return prehookReturnValue;
    } else {
        abortHandlerOptions.onNoUnsavedChangesCallback();
        return true;
    }
};

module.exports = {
    contextList: {},

    registerContext: function (context) {
        if (
            typeof context.key !== 'string' || // Unique key to identify your context
            // (Registering again will override previous ones with same key).

            typeof context.routeHash !== 'string' || // Hash route to set back to on "continue editing" choice
            // (this is specific to Backbone routing)

            typeof context.isActiveContext !== 'function' || // whether this context is active / visible
            typeof context.hasUnsaved !== 'function' // function to determine if this context has unsaved changes
        ) {
            throw new Error('Context needs: {key, isActiveContext, hasUnsaved, routeHash}');
            // This might change in the future if we did non settings related contexts...
        }

        this.contextList[context.key] = context;
    },

    checkAbortBrowserNav: function (event) {
        var cntFn = function () {
            // discard changes outcomes result in browser navigating to expected url
            if (typeof event.target.href !== 'undefined') {
                location.href = event.target.href;
            } else {
                location.href = $(event.target).closest('a').attr('href');
            }
        };

        return privateHandleAbort(this.contextList, {
            ifUnsavedPreHookCallback: function () {},
            onContinueEditingCallback: function (unsavedContext) {}, // Do nothing, preventDefault avoided any url change
            onDiscardChangesCallback: cntFn,
            onNoUnsavedChangesCallback: cntFn,
        });
    },

    // This should be spliced in the Router.execute part of a pages router.
    // routerContinueEditingCallback is used in cases where the route controls DOM elements
    // That also need updating (IE, the left hand pane in settings)
    checkAbortRouteChange: function (discardChangesCallback, routerContinueEditingCallback) {
        return privateHandleAbort(this.contextList, {
            ifUnsavedPreHookCallback: function () {},
            onContinueEditingCallback: function (unsavedContext) {
                // Tell backbone to replace the hash with the context's hash that has unsaved changes
                // (IE, unwinding the route change)
                Backbone.history.navigate(unsavedContext.routeHash, { trigger: false, replace: true });
                if (typeof routerContinueEditingCallback === 'function') {
                    routerContinueEditingCallback(unsavedContext.routeHash);
                }
            },
            onDiscardChangesCallback: function () {
                discardChangesCallback();
            },
            onNoUnsavedChangesCallback: function () {
                discardChangesCallback();
            },
        });
    },
};
