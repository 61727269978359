'use strict';
/*jshint newcap:false */
/*global Intercom */

module.exports.event = function (label, metadata) {
    if (typeof Intercom === 'function') {
        Intercom('trackEvent', label, metadata);

        //delay checking for messages
        window.setTimeout(function () {
            Intercom('update');
        }, 500);
    }
};

module.exports.messagePoll = function () {
    if (typeof Intercom === 'function') {
        //delay checking for messages
        window.setTimeout(function () {
            console.log('polling for intercom messages');
            Intercom('update');
        }, 5000);
    }
};
