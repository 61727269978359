import React from 'react';
import Handlebars from 'handlebars';

// couldn't find helper actions must be pass through props
// couldn't find helper dismiss must be pass through props
// couldn't find helper icon must be pass through props
// couldn't find helper primary must be pass through props
// couldn't find helper disabled must be pass through props
// couldn't find helper cancel must be pass through props

const hbr = `
{{#if showMoreOptions}}
    <div class="loadingDefault">
    <div class="dropup">
        <a class="dropdown-toggle lefty gradient btn btn-default js-more-options"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More options <i class="fa fa-caret-down"></i></a>
        <ul class="dropdown-menu" >
            {{#actions}}
            <li><span class= "{{#if disabled}}disabled{{/if}} btn {{additionalClasses}}" {{#dismiss}}data-dismiss="modal"{{/dismiss}}>{{#icon}}{{{this}}} {{/icon}}{{{text}}}</span></li>
            {{/actions}}
        </ul>
    </div>
    {{#primary}}
            <a {{#if href}}href="{{href}}"{{/if}} type="button" data-action="primary" {{#if isDisabled}}disabled{{/if}} {{#dismiss}}data-dismiss="modal"{{/dismiss}} class="{{additionalClasses}} btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} js-primary" {{#disabled}}disabled{{/disabled}}>{{{text}}}</a>
    {{/primary}}
    </div>
{{else}}
    <div class="loadingDefault">
    {{#actions}}
        <button {{#if disabled}}disabled{{/if}} class="btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} {{additionalClasses}}" {{#dismiss}}data-dismiss="modal"{{/dismiss}}>{{#icon}}{{{this}}} {{/icon}}{{{text}}}</button>
    {{/actions}}
    {{#cancel}}
        <button type="button" {{#if isDisabled}}disabled{{/if}} class="btn btn-default js-cancel-button" data-dismiss="modal">Cancel</button>
    {{/cancel}}
    {{#primary}}
        <a {{#if href}}href="{{href}}"{{/if}} type="button" data-action="primary" {{#if isDisabled}}disabled{{/if}} {{#dismiss}}data-dismiss="modal"{{/dismiss}} class="{{additionalClasses}} btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} js-primary buttonText" {{#disabled}}disabled{{/disabled}}><span class="buttonText">{{{text}}}</span><span class="buttonLoadingIndicator fa fa-spinner fa-pulse"></span></a>
    {{/primary}}
    </div>
    <div class="loadingFeedback">
    {{#actions}}
        <a class="btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} disabled {{additionalClasses}}">{{#icon}}{{{this}}} {{/icon}}{{{text}}}</a>
    {{/actions}}
    {{#cancel}}
    <a class="btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} disabled">Cancel</a>
    {{/cancel}}
    {{#primary}}
    <a class="{{additionalClasses}} btn btn-{{#if buttonClass}}{{buttonClass}}{{else}}default{{/if}} disabled">{{loadingText}}</a>
    {{/primary}}
    </div>
{{/if}}
`.trim();
let template = false;
export const getTemplate = () => {
    return Handlebars.compile(hbr);
};
export default (props) => {
    template = template ? template : getTemplate();
    return template(props);
};
