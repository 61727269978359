var $ = require('jquery'),
    _ = require('underscore'),
    moment = require('moment'),
    MobileDetect = require('./../common/mobile_detect_library'),
    md = new MobileDetect(window.navigator.userAgent);

require('bootstrap');
//var sml;

function ios8Fix() {
    //This mess adds some padding if using iOS 8 on iphone/ipod.
    //Why? Because tapping near the bottom of the page
    // when in 'full screen' mode (which is entered into automatically when scrolling),
    // makes the browser footer appear instead of
    // triggering whatever you tapped on. Lame, huh? So we'll add some padding.
    var isGTEiOS8 = function () {
        return navigator.userAgent.match(/(iPad|iPhone|iPod touch);.*CPU.*OS [89]_\d/i);
    };
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    if (isGTEiOS8() && isSafari) {
        $('body').addClass('iosPad');
    }
}

//do stuff
$(function () {
    /*if ($(window).width() <= 601) {
        sml = true;
    }*/

    /*
     Init stuff
     */

    //MAIN NAV

    $(document).on('click', '#slide-nav .navbarMenuButton, #wrapBlocker, .toClose', function () {
        var selected = $('#slide-nav').hasClass('slide-active');

        if (!selected) {
            window.scrollTo(0, 0);
        }

        //$('#slidemenu').css('display', (selected) ? 'none' : 'block');

        $(this).toggleClass('slide-active', !selected);
        $('#slidemenu').toggleClass('slide-active');
        $('#calendar').toggleClass('iosScroll');
        $('#leftSide').toggleClass('iosScroll');

        //TODO: just do body. why all this?? need to ensure styles / tests still work if changing this though.
        $('#page-content, .navbar, body, .navbar-header').toggleClass('slide-active');
    });

    /* .openMainMenu is a generic inline link if we want some other page element to open the menu */
    $(document).on('click', '.openMainMenu', function () {
        $('#slide-nav .navbar-toggle').trigger('click');
    });

    /*$("body.slide-active").on("swipeleft",function(){
        $('#page-content, .navbar, body, .navbar-header').toggleClass('slide-active');
    });
*/
    //scroll listener, for applying shadow to mobile nav (and maybe other stuff later...)
    /*$(window).bind('scroll', function() {
        var scrollTop = $(window).scrollTop();
        if (scrollTop  > 55) {
            if (!$('body').hasClass('sticky')) {
                $('body').addClass('sticky');
            }
        } else {
            if ($('body').hasClass('sticky')) {
                $('body').removeClass('sticky');
            }
        }
    });*/

    //category selection modal
    /*$(document).on('click', '.catNav li a', function () {

        $('.catNav-active').not(this.closest('li')).removeClass('catNav-active');
        $(this).closest('li').toggleClass('catNav-active');

        // $('#catModal').modal('layout');
        // uuum is this a good idea? maybe smarter to detect if the bottom is touching the bottom of the view
        // (or if there is a scroll bar present), then anchor the modal to the top instead

    });*/

    //close dropdown and hide slide menu (if visible) when clicking notifications
    $('.notifications').click(function () {
        $(this).closest('.dropdown-menu').prev().dropdown('toggle');

        if ($('#navbar-height-col').is(':visible')) {
            $('.toClose').trigger('click');
        }
    });

    ios8Fix();

    //  $('[data-toggle="popover"]').popover();
});
