'use strict';
var $ = require('jquery');

var MobileDetect = require('./../common/mobile_detect_library');
var md = new MobileDetect(window.navigator.userAgent);

/**
 * Incude this file after the fancybox lib to override the defaults
 */

//make the close button look more like the bootstrap modal
$.extend($.fancybox.defaults.tpl, {
    closeBtn:
        '<button type="button" title="Close" class="fancybox-item fancybox-close close" href="javascript:;">&times;</button>',
});

$.extend($.fancybox.defaults, {
    // position: 'absolute',
    // bottom: 0,
    // right: 0,
    top: '0px',
    type: 'inline',
    padding: 0,
    margin: 10,
    helpers: {
        overlay: { closeClick: false },
    },
    keys: {
        close: null,
    },
    topRatio: 0,
    autoCenter: false,
    autoSize: false,
    fitToView: false,
    width: '100%',
    maxWidth: '650px',
    height: 'auto',
    // maxHeight: '100%',
    autoResize: true,

    afterLoad: function (fancyboxInstance) {
        //if there is a width explicitly set then we override the fancybox default with it
        /**
         * Ideally this should be done via the fancy box constructor
         * (ie $.fancybox({'href': '#modalId', maxWidth: '1000px'}); )
         **/
        var $container = $(fancyboxInstance.href);
        var widthStyle = $container.prop('style').width; //this gets the actual defined inline style
        var maxWidthStyle = $container.prop('style').maxWidth; //this gets the actual defined inline style

        if (widthStyle) {
            fancyboxInstance.maxWidth = widthStyle;
        } else if (maxWidthStyle) {
            fancyboxInstance.maxWidth = maxWidthStyle;
        }
    },

    beforeShow: function () {
        $('.fancybox-inner').css('height', 'auto');
    },
});

$(document).ready(function () {
    // add body class for phones, used to manipulate how modals appear.
    // also scrolls to top left when they open so the modal is instantly visible instead of just an overlay
    // if (md.phone() && !md.tablet()) {
    //     $('body').addClass('isAPhone');
    //     $.extend($.fancybox.defaults, {
    //         beforeLoad: function () {
    //             window.scroll(0, 0);
    //         },
    //         fitToView: false
    //     });
    // }
});
