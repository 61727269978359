/**
 * Cookie helper functions. Taken from Mozilla (https://developer.mozilla.org/en-US/docs/Web/API/document.cookie)
 *
 * Syntaxes:
 * cookie.setItem(name, value[, end[, path[, domain[, secure]]]])
 * cookie.getItem(name)
 * cookie.removeItem(name[, path])
 * cookie.hasItem(name)
 * cookie.keys()
 */
module.exports = {
    getItem: function (sKey) {
        return (
            decodeURI(
                document.cookie.replace(
                    new RegExp(
                        '(?:(?:^|.*;)\\s*' +
                            encodeURI(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
                            '\\s*\\=\\s*([^;]*).*$)|^.*$'
                    ),
                    '$1'
                )
            ) || null
        );
    },
    setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
        var sExpires = '';
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
            return false;
        }

        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires =
                        vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
                    break;
                case String:
                    sExpires = '; expires=' + vEnd;
                    break;
                case Date: // eslint-disable-line k1-custom-rules/no-native-date
                    sExpires = '; expires=' + vEnd.toGMTString();
                    break;
            }
        }
        document.cookie =
            encodeURI(sKey) +
            '=' +
            encodeURI(sValue) +
            sExpires +
            (sDomain ? '; domain=' + sDomain : '') +
            (sPath ? '; path=' + sPath : '') +
            (bSecure ? '; secure' : '');
        return true;
    },
    removeItem: function (sKey, sPath, sDomain) {
        if (!sKey || !this.hasItem(sKey)) {
            return false;
        }
        document.cookie =
            encodeURI(sKey) +
            '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
            (sPath ? '; path=' + sPath : '') +
            (sDomain ? '; domain=' + sDomain : '');
        return true;
    },
    hasItem: function (sKey) {
        return new RegExp('(?:^|;\\s*)' + encodeURI(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=').test(
            document.cookie
        );
    },
    keys: function () {
        var nIdx,
            aKeys = document.cookie
                .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
                .split(/\s*(?:\=[^;]*)?;\s*/);

        for (nIdx = 0; nIdx < aKeys.length; nIdx++) {
            aKeys[nIdx] = decodeURI(aKeys[nIdx]);
        }
        return aKeys;
    },
};
