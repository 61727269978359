import React from 'react';
import Handlebars from 'handlebars';

const hbr = `
<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header modal-header-danger modal-alert">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            <h4 class="modal-title">Subterfuge</h4>
        </div>

        <div class="modal-body">
            <div>
                <h3>Business info</h3>
                <div>
                    <table class="table table-striped mTopL">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {{#each data}}
                                <tr>
                                    <td>{{@key}}</td>
                                    <td>{{this}}</td>
                                </tr>
                            {{/each}}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

`.trim();
let template = false;
export const getTemplate = () => {
    return Handlebars.compile(hbr);
};
export default (props) => {
    template = template ? template : getTemplate();
    return template(props);
};
