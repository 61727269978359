var jQuery = require('jquery');

(function ($) {
    'use strict';
    /**
     * @function
     * @property {object} jQuery plugin which runs handler function once specified element is inserted into the DOM
     * @param {function} handler A function to execute at the time when the element is inserted
     * @param {bool} shouldRunHandlerOnce Optional: if true, handler is unbound after its first invocation
     * @example $(selector).waitUntilExists(function);
     */

    $.fn.waitUntilExists = function (handler, shouldRunHandlerOnce, isChild) {
        var found = 'found';
        var $this = $(this.selector);
        var $elements = $this
            .filter(':visible')
            .not(function () {
                return $(this).attr('data-found') === 'true';
            })
            .each(handler)
            .attr('data-found', true)
            .attr('data-toremove', false);
        if (!isChild) {
            (window.waitUntilExists_Intervals = window.waitUntilExists_Intervals || {})[this.selector] =
                window.setInterval(function () {
                    $this.waitUntilExists(handler, shouldRunHandlerOnce, true);
                }, 500);
        } else if (shouldRunHandlerOnce && $elements.length) {
            window.clearInterval(window.waitUntilExists_Intervals[this.selector]);
        }

        return $this;
    };
})(jQuery);

(function ($) {
    'use strict';
    $.fn.waitUntilDisappear = function (handler, shouldRunHandlerOnce, isChild) {
        var found = 'toremove';
        var $this = $(this.selector);
        var $elements = $this
            .filter(':hidden')
            .not(function () {
                return $(this).attr('data-toremove') === 'true';
            })
            .each(handler)
            .attr('data-toremove', true)
            .attr('data-found', false);

        if (!isChild) {
            (window.waitUntilDisappear_Intervals = window.waitUntilDisappear_Intervals || {})[this.selector] =
                window.setInterval(function () {
                    $this.waitUntilDisappear(handler, shouldRunHandlerOnce, true);
                }, 500);
        } else if (shouldRunHandlerOnce && $elements.length) {
            window.clearInterval(window.waitUntilDisappear_Intervals[this.selector]);
        }

        return $this;
    };
})(jQuery);
