'use strict';

var $ = require('jquery'),
    _ = require('underscore'),
    cookie = require('./../common/cookie'),
    intercom = require('./../common/intercom'),
    modal_helper = require('./modals'),
    UnsavedChangesHelper = require('./../common/unsavedChangesHelper');
var MobileDetect = require('./../common/mobile_detect_library');
var md = new MobileDetect(window.navigator.userAgent);

require('./../common/responsive');
require('./../common/chat');

require('blockui');
// require('bootstrap'); // loaded in /common/responsive
require('jquery-ui');
require('select2');
var select2_custom = require('./../ui-components/select2-custom-features');

require('./../common/standard_handlers');

var token = '';
var prod = '';

var K1GLOBALS = {
    timeoutID: {},
    intervalID: {},
};

var dismissBurgerMenu = function () {
    // Ewwww... This yuk is copied from responsive.js
    var selected = $('#slide-nav').hasClass('slide-active');

    if (selected) {
        $('#calendar, #leftSide').toggleClass('iosScroll');
        $(
            '#slidemenu, #slide-nav, .navbarMenuButton, #wrapBlocker, #page-content, .navbar, body, .navbar-header'
        ).toggleClass('slide-active');
    }
};

$(document).ready(function () {
    /**
     * skip showing on tc page
     *
     */
    if (window.location.pathname.search('terms') > 0) {
        return false;
    }

    require('./../common/waitUntilExists');

    $(document).on('click', '#e-bar-account', function (event) {
        event.stopPropagation();
        var $this = $(this),
            $ebar = $('#e-bar'),
            $accountDropdown = $('#accountDropdown');

        if ($accountDropdown.is(':visible')) {
            $this.removeClass('dropdownParent');
            $ebar.find('.ebarDropdown').hide();
            return;
        }

        $ebar.find('.hanger').removeClass('dropdownParent');
        $ebar.find('.ebarDropdown').hide();

        $accountDropdown.show().position({
            my: 'right top',
            at: 'right bottom+5',
            of: $this,
        });

        $this.addClass('dropdownParent');
        $(document).one('click', function () {
            var $ebar = $('#e-bar'),
                $accountDropdown = $('#accountDropdown');

            if ($accountDropdown.is(':visible')) {
                $accountDropdown.removeClass('dropdownParent');
                $ebar.find('.ebarDropdown').hide();
                return;
            }
        });
    });

    //logout. - fire a logout at all products that e-bar incorporates
    $(document).on('click', '#e-bar-logout', function () {
        UnsavedChangesHelper.checkAbortRouteChange(
            function () {
                // This code will be run if no unsaved changes / or user does not care.
                $('body').trigger('logout', 'clicked-logout');
            },
            function () {}
        );

        dismissBurgerMenu();
    });

    $('#navBranchSelect').on('change', function (e) {
        $.get('/k1/main/switchCurrentBusiness/' + $(this).val(), function () {
            window.location.reload();
        });
    });
});

var mouseIsInSubNav = false;

// ontouchstart works on most browsers, navigator.msMaxTouchPoints works on ie10
var iAmTouchy = 'ontouchstart' in window || navigator.msMaxTouchPoints;

var processing = processing || null;

/**This function is used to triger the 'change' ever when programmatically
 * set the 'checked' property. We used to override $.propHooks.checked,
 * which had unintended consequences. This is a work around
 *
 * @type {{set: $.propHooks.checkedAndFireEvent.set}}
 */
$.propHooks.checkedAndFireEvent = {
    set: function (el, value) {
        el.checked = value;
        $(el).trigger('change');
    },
};

$(window).on('pageshow', function () {
    // This function was originally clogging up our intercom events with dynamic named page loaded events.
    // You can only have 120 events in Intercom which are quickly used up when every page name creates an event in
    // a dynamic web app.

    // Only these three page loads are recoded, because there are existing messages that rely on these events.
    // You should not add more pages to this array, intead you should use "page targeting".
    var STATIC_PAGES_TO_RECORD = {
        appointments: true,
        services: true,
        'marketing/campaigns': true,
    };

    var page = window.location.pathname.replace('/k1', '').replace('/', '');

    if (page in STATIC_PAGES_TO_RECORD && STATIC_PAGES_TO_RECORD[page] === true) {
        intercom.event('loaded-' + page + '-page');
    }
});

//function to reset form fields.
$.fn.reset = function () {
    $(this).each(function () {
        this.reset();
    });
};

$(function () {
    var IE6UPDATE_OPTIONS = {
        icons_path: 'http://static.ie6update.com/hosted/ie6update/images/',
        url: 'http://www.example.com/',
    };

    //dont follow # links
    $(document).on('click', 'a', function (e) {
        var l = $(this).attr('href');

        if (l === '#') {
            e.preventDefault();
        }
    });

    // Commented out for hotfix 1.177.3:
    //    Apple devices with "home screen short cuts" are having issues with this change (we suspect).

    $(document).on('click', '#navWrap a', function (event) {
        if (
            $(this).attr('href') !== '#' &&
            $(this).attr('target') !== '_blank' && // New tab don't care!
            !$(this).hasClass('dropdown-toggle') &&
            event.ctrlKey !== true // New tab don't care!
        ) {
            event.preventDefault();

            var returnValue = UnsavedChangesHelper.checkAbortBrowserNav(event);

            dismissBurgerMenu();

            return returnValue;
        }
    });

    modal_helper.showModals();

    //date ui
    $('#date').datepicker();

    $(document).on('click', '#kitomba_login_help_button', function () {
        $('#kitomba_login_help').slideToggle(300);
    });

    $('#referEmailForm').submit(function (event) {
        event.preventDefault();
        var data = $(this).serialize();
        $.ajax({
            url: '/k1/admin/sendReferEmail',
            type: 'POST',
            data: data,
            dataType: 'json',
        }).done(function () {});
    });

    /**
     * Chain selector
     */
    $(document).on('click', '#businessSwitchModal .list-group a', function (e) {
        e.preventDefault();
        var thisVal = $(this).attr('data-value');

        //hook in unsavedChangesHelper here.
        UnsavedChangesHelper.checkAbortRouteChange(
            function () {
                // This code will be run if no unsaved changes / or user does not care.
                //Use this to change business and stay on the same page instead of redirecting to the home tab
                cookie.removeItem('DAYSPREF'); //for client days view
                window.location = '/k1/main/switchChainOrBusiness/' + thisVal;
            },
            function () {} // Don't do anything here.
        );

        dismissBurgerMenu();
    });

    //e.g. use the same prefixes / suffixes for ID/class names

    $(document).on('click', '.nav_a.hasDropdown', function (e) {
        e.stopPropagation();

        var $this = $(this),
            $dropdown = $('#' + $this.attr('rel'));

        var $id = $this.attr('id');

        if ($(this).hasClass('dropdownParent')) {
            $('#' + $(this).attr('rel')).hide();
            $(this).removeClass('dropdownParent');
        } else {
            $dropdown.show().position({
                my: 'left top',
                at: 'left bottom',
                of: $this,
            });
            $this.addClass('dropdownParent');
        }

        $('.nav_a.hasDropdown').each(function (k, v) {
            if ($id !== $(this).attr('id')) {
                $('#' + $(this).attr('rel')).hide();
                $(this).removeClass('dropdownParent');
            }
        });

        $(document).one('click', function () {
            $('.nav_a.hasDropdown').each(function (k, v) {
                $('#' + $(this).attr('rel')).hide();
                $(this).removeClass('dropdownParent');
            });
        });
    });

    //disabled to enable marketing pages
    //    $('.nav_disabled').click(
    //        function (e) {
    //            var product = $(this).html().toLowerCase();
    //            var dontLoad = ['kmail', 'benchmark', 'Online Booking'];
    //
    //            if (dontLoad.indexOf(product) !== -1) {
    //                e.preventDefault();
    //            }
    //        });

    /*$(document).on('click', '#showBranchChooser', function () {

        intercom.event('opened-branch-chooser');
        var $this = $(this);
        var $selectedBusiness = $(".selectedBusiness");

        $('#chain_selector').show().position({
            my: 'left bottom',
            at: 'left bottom',
            of: $selectedBusiness,
            'offset': '0 2px'
        });

        $this.add($selectedBusiness).fadeOut('fast');

    });*/

    if (iAmTouchy) {
        $(document.body).addClass('touchy');
    } else {
        $(document.body).addClass('noTouchy');
    }
});

var ProductPermissions_Model = function () {
    this.noAccess = {
        src: '/images/k1/white.png',
        title: 'Click to give access',
    };
    this.admin = {
        src: '/images/k1/black.png',
        title: 'Admin',
    };
    this.user = {
        src: '/images/k1/blue.png',
        title: 'User',
    };
};

var ProductPermissions = new ProductPermissions_Model();

//utility function?
function clone(obj) {
    var temp = new obj.constructor();
    var key;
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    for (key in obj) {
        temp[key] = clone(obj[key]);
    }
    return temp;
}

var templates = {};

var bootstrap = (function () {
    var boot = {};

    /**
     * Load templates
     */
    boot.templates = function () {
        var ext_template = function (str, data) {
            // match "<% include template-id %>"
            return _.template(
                str.replace(/<%\s*include\s*(.*?)\s*%>/g, function (match, templateId) {
                    var el = $('#' + templateId);
                    return el ? el.html() : '';
                }),
                data
            );
        };

        // Load templates
        $("script[type='text/template']").each(function (i, e) {
            var $elem = $(e);

            templates[$elem.attr('id')] = ext_template($elem.html());
        });
    };

    return boot;
})();

$(document).ready(bootstrap.templates);

//refresh window if ajax request fails because of no auth
$(document).ready(function () {
    $(document).on('click', '#bottomNoteClose', function () {
        $('#bottomNote').fadeOut('fast');
    });
});
