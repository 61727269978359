module.exports = {
    addStaffIconToDropdown: function (state) {
        if (!state) {
            return '';
        }
        if (!state.id) {
            return state.text;
        }
        return (
            '<span class="personIcon select2Icon">' + '<i class="fa fa-user"></i>' + '</span> ' + state.text
        );
    },
    addHomeIconToDropdown: function (state) {
        if (!state.id) {
            return state.text;
        }
        return '<span class="houseIcon select2Icon"><i class="fa-solid fa-shop"></i></span> ' + state.text;
    },
    addZoomIconToDropdown: function (state) {
        if (!state.id) {
            return state.text;
        }
        return '<span class="zoomIcon select2Icon"></span> ' + state.text;
    },

    addDaySelectIconToDropdown: function (state) {
        if (!state.id) {
            return state.text;
        }
        return (
            '<span class="fa fa-calendar select2Icon" style="font-size: 18px; padding-left: 1px; padding-top: 2px;"></span> ' +
            state.text
        );
    },
};
