/*global _rollbarConfig */
var rollbar = require('rollbar');

if (typeof _rollbarConfig !== 'undefined') {
    _rollbarConfig.checkIgnore = function (isUncaught, args, payload) {
        var iPhoneBug;
        var crossDomainFirefox;
        var oldBrowser;
        var npObject;
        var extension;
        var otherDomain;
        var badSource;
        var msg;
        var filename;
        var trace;
        var data;
        try {
            //var browser = data.client.javascript.browser;

            if (typeof payload.body.trace === 'undefined') {
                return false;
            }

            trace = payload.body.trace;
            filename = trace.frames[0].filename;
            msg = trace.exception.description;

            /* Don't cloud the error logs with extension errors */
            badSource = filename === '(unknown)';
            otherDomain = filename.indexOf(window.location.origin) !== 0;
            extension = msg.indexOf('Error connecting to extension') !== -1;
            npObject = msg.indexOf('NPObject') !== -1;
            oldBrowser = msg === 'Object.defineProperty is not a function';
            crossDomainFirefox = msg === 'Script error.';
            iPhoneBug = msg === "null is not an object (evaluating 'elt.parentNode')";

            if (
                badSource ||
                otherDomain ||
                extension ||
                npObject ||
                oldBrowser ||
                crossDomainFirefox ||
                iPhoneBug
            ) {
                return true;
            }
        } catch (e) {
            console.log(e);
        }
        return false;
    };

    var Rollbar = rollbar.init(_rollbarConfig);
    window.Rollbar = Rollbar;
} else {
    window.Rollbar = {};
    window.Rollbar.error = function () {};
    window.Rollbar.warn = function () {};
}

module.exports = window.Rollbar;
