import React from 'react';
import Handlebars from 'handlebars';

// couldn't find helper id must be pass through props

const hbr = `
<div class="modal-dialog {{additionalClasses}}" {{#if contentWidth}}style="max-width: 100%; width: {{contentWidth}}px;"{{/if}} data-backdrop="static" data-keyboard="false">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close disable-global-close js-header-close-button" data-dismiss="modal" aria-hidden="true">&times;</button>
            <h4 class="modal-title" {{#id}}id="{{id}}"{{/id}}>{{{title}}}<span></span></h4>
        </div>
        {{#if fixedPanel}}<div class="modal-fixed-panel"></div>{{/if}}
        <div class="modal-body"></div>
        <div class="modal-footer"></div>
    </div>
</div>
`.trim();
let template = false;
export const getTemplate = () => {
    return Handlebars.compile(hbr);
};
export default (props) => {
    template = template ? template : getTemplate();
    return template(props);
};
