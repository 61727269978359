/*jslint nomen: true*/
'use strict';
const { renderBuilder } = require('src/common/helpers/handlebars');
var $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');

Backbone.$ = $;
var template = renderBuilder(require('src/backbone/templates/hiddenForm.js').default);

module.exports = Backbone.View.extend({
    initialize: function (options) {
        var defaults = {
            method: 'POST',
            parameter_map: {},
        };

        this.options = _.extend(defaults, options);
        this.render();
    },

    getFormId: function () {
        return 'hidden_form_' + this.cid;
    },

    getParameters: function () {
        var parameters = [];

        _.each(this.options.parameter_map, function (value, key) {
            parameters.push({
                key: key,
                value: value,
                isArray: _.isArray(value),
            });
        });

        return parameters;
    },

    render: function () {
        var extraData = {
            form_id: this.getFormId(),
            parameters: this.getParameters(),
        };
        var data = _.extend(extraData, this.options);
        $('body').append(template(data));
    },

    doRedirect: function () {
        $('#' + this.getFormId()).submit();
    },
});
