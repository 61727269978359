/*global gtag */
'use strict';

window.GADimensions = window.GADimensions || {}; // make sure it exists to prevent unit tests from crashing

/* global module, ga */
module.exports = {
    loggingEnabled: false,

    //Category strings.
    // Be careful modifying these as it will mess up historical GA events
    CUSTOM_FORMS: 'Client Forms',
    CUSTOM_FORMS_BUILDER: 'Form builder',

    INSTAGRAM_INTEGRATION: 'Instagram Integration',

    REPEAT_APPOINTMENTS: 'Repeat appointments',
    DEPOSIT_SETTINGS: 'Online Booking Deposit settings',
    VOUCHER_SETTINGS: 'Online Voucher Sales settings',
    PAYMENT_GATEWAYS: 'Payment gateways',
    SETTINGS: 'Settings',
    CLIENTS: 'Clients',
    CLIENT_CARD_HISTORY: 'Client card history',
    TIMESHEETS: 'Time Sheets',
    APPOINTMENT_BOOK: 'Appointment book',
    INVOICING: 'Invoicing',
    STAFF_AND_USERS: 'Staff & Users',
    VOUCHERS: 'Vouchers',
    GIVE_TO_CLIENT: 'Give-to-client',
    WAITLIST: 'Waitlist',
    VOUCHER: 'Voucher',
    SMART_OB_DEPOSITS: 'Smart OB Deposits',
    MESSAGES: 'Messages',
    INBOX: 'Inbox',
    REMINDER_SETTINGS: 'Reminder settings',
    SALES: 'Sales',
    BUSINESS_LEAVE: 'Business leave',
    ROOMS_AND_EQUIPMENT: 'Rooms & Equipment',
    TIMETABLES: 'Timetables',
    SECURITY_LOG: 'Security Log',

    trackPageView: function (trackEvent) {
        if (typeof gtag === 'function') {
            gtag('event', 'page_view', {
                page_path: '/k1/' + trackEvent,
            });
            if (this.loggingEnabled) {
                console.log('track PageView: ' + Array.from(arguments).join(' | '));
            }
        }
    },

    /**
     * @param {string} eventCategory - Typically the area ok k1 being interacted with, hopefully using a constant from the top of this class.
     * @param {string} eventAction - The type of interaction, or what the user is doing, for example: "Clicked resend failed connections".
     * @param {string=} [eventLabel] - Useful for categorizing events, for example we use this field to distinguish events for different k1 report types.
     * @param {number=} [eventValue] - A numeric value associated with the event
     *
     * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
     */
    trackEvent: function (eventCategory, eventAction, eventLabel, eventValue) {
        if (typeof gtag === 'function') {
            gtag('event', 'general', {
                event_category: eventCategory,
                event_action: eventAction,
                event_label: eventLabel,
                value: eventValue,
            });
            if (this.loggingEnabled) {
                console.log('track Event: ' + Array.from(arguments).join(' | '));
            }
        }
    },

    trackDimension: function (dimension, dimensionValue) {
        if (typeof gtag === 'function') {
            gtag('set', dimension, dimensionValue);
            if (this.loggingEnabled) {
                console.log('track Dimension: ' + Array.from(arguments).join(' | '));
            }
        }
    },
};
