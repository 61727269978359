'use strict';
/*jshint newcap:false */
/*global Intercom, $zopim */
var $ = require('jquery');

function addChatOpenClassToBody() {
    $('body').addClass('chatOpen');
}

function removeChatOpenClassToBody() {
    $('body').removeClass('chatOpen');
}

//zopim custom show hide code
$(document).on('zopim_ready', function (e, credentials) {
    $zopim(function () {
        $zopim.livechat.hideAll();
        $zopim.livechat.window.onHide(removeChatOpenClassToBody);

        $('#e-bar-logout').on('click', function () {
            $zopim.livechat.endChat();
        });

        var $chatButton = $('.chatBtn');

        $chatButton.on('click', function () {
            Intercom('hide');
            if (!$zopim.livechat.window.getDisplay()) {
                addChatOpenClassToBody();
                $zopim.livechat.window.show();
            } else {
                removeChatOpenClassToBody();
                $zopim.livechat.hideAll();
            }
        });

        //hide when showing intercom
        $('.notifications').on('click', function () {
            //just a minamize
            $zopim.livechat.hideAll();
        });

        //we used to fetch these details from Zendesk, but it was taking 6% of server time, so now we just
        //use our version of the information. The important thing is email as that is what the Zendesk end user is
        //keyed by. Secondly setting the enduser tags on the chat ticket was never required.
        $zopim.livechat.setName(credentials.name);
        $zopim.livechat.setEmail(credentials.email);
        $chatButton.show();
    });
});

$(document).ready(function () {
    if (typeof Intercom !== 'undefined') {
        Intercom('onShow', addChatOpenClassToBody);
        Intercom('onHide', removeChatOpenClassToBody);
    }
});

//intercom custom show hide code   --intercom-messenger-frame
$(document).on('intercom_chat_ready', function () {
    //call back hell
    $(document).ready(function () {
        $('.chatBtn').show();

        $('.chatBtn').on('click', function () {
            if ($('.intercom-messenger-frame').is(':visible')) {
                Intercom('hide');
            } else {
                Intercom('showNewMessage');
            }
        });
    });
});
