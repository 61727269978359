'use strict';
const { renderBuilder } = require('src/common/helpers/handlebars');
/* global require, window, module */
var $ = require('jquery'),
    _ = require('underscore'),
    inlineSpinnerTemplate = renderBuilder(require('src/backbone/templates/inlineSpinner.js').default),
    modalHelper = require('./../ui-components/modals');

/**
 * Create a loading controller for the given element and options
 *
 * By default, will assign the class 'loading' to the element
 *
 * e.g. loading($element).until(promise)
 * Will add class 'loading' to $element, and remove it when the given promise resolves
 */
var loading = (function () {
    return function (parent, options) {
        // track promises, don't remove the overlay until the counter hits zero
        var counter = 0;
        var DELAY = 0; // ms
        var SHOW_IMMEDIATELY = -1;
        var $parent = parent;

        if (!parent.jquery) {
            $parent = $($parent);
        }

        var defaultClassNames = ['loading'];

        var resolveElement = function ($el) {
            if (typeof $el === 'function') {
                return $el();
            }
            return $el;
        };

        var setLoading = function ($el) {
            _.each(options.classNames, function (className) {
                $el.addClass(className);
            });
        };

        var unsetLoading = function ($el) {
            _.each(options.classNames, function (className) {
                $el.removeClass(className);
            });
        };

        if (typeof $parent === 'undefined') {
            $parent = $.bind($, 'body');
        }

        if (typeof options !== 'object') {
            options = {};
        }

        // Show a block ui style spinner.
        if (options.useSpinner) {
            options.show = modalHelper.showModalLoadingFeedback.bind(modalHelper);
            options.hide = modalHelper.hideModalLoadingFeedback.bind(modalHelper);
        }

        // "Replace" an icon in button with a spinner.
        if (options.replaceIconWithSpinner) {
            var $icon = $parent.find('.fa');

            options.show = function () {
                if ($parent.hasClass('btn')) {
                    $parent.attr('disabled', true);
                }

                $icon.hide();
                resolveElement($icon).after(inlineSpinnerTemplate);
            };
            options.hide = function () {
                if ($parent.hasClass('btn')) {
                    $parent.attr('disabled', false);
                }

                resolveElement($icon.parent()).find('.js-inline-spinner').remove();
                $icon.show();
            };
        }

        _.defaults(options, {
            classNames: defaultClassNames,
            show: setLoading,
            hide: unsetLoading,
        });

        return {
            delay: function (ms) {
                DELAY = ms;

                return this;
            },

            showImmediately: function () {
                DELAY = SHOW_IMMEDIATELY;

                return this;
            },

            /**
             *
             * @param {...Thenable|Promise} promises
             * @return {Thenable|Promise<Awaited>}
             */
            until: function (...promises) {
                let promise, method;
                // Supporting native promises now that we are using async await,
                // while maintaining backward compatibility with jquery deferred.
                if (promises.length === 1 && promises[0] instanceof Promise) {
                    // Only cater for one native promise at a time.
                    // We can use Promise.all externally if we really want to handle multiple.
                    // Opting into Promise.all for everything returns a promise that resolves to an array,
                    // which we don't want in 99% of cases.
                    promise = promises[0];
                    method = 'finally';
                } else {
                    promise = $.when(...promises);
                    method = 'always';
                }

                let timeoutId;
                // delay the display of the loading screen so that instantly resolved promises won't trigger a flash of 'loading'
                if (DELAY === SHOW_IMMEDIATELY) {
                    // Although we may specifiably want to show loading feed back immediately, if for instance we are showing a loading skeleton.
                    options.show(resolveElement($parent));
                } else {
                    timeoutId = window.setTimeout(() => {
                        options.show(resolveElement($parent));
                    }, DELAY);
                }

                counter += 1;

                promise[method](() => {
                    counter = Math.max(0, counter - 1);

                    // cancel timeout, in case we have resolved fast enough not to bother
                    if (timeoutId) {
                        window.clearTimeout(timeoutId);
                    }

                    if (counter < 1) {
                        // hide loading screen
                        options.hide(resolveElement($parent));
                    }
                });

                return promise;
            },

            counter: counter,
        };
    };
})();

module.exports = loading;

/**
 * Create a loading controller for a spinner positioned in the center of the given element
 *
 * e.g. loading.inlineButtonSpinner($buttonElement).until(ajaxRequest)
 */
module.exports.inlineButtonSpinner = (function () {
    var $spinner = $("<div class='inline-loading-spinner'>"); // needs a div wrapper as the <i> element ignores transform - even when set to block
    $spinner.css({
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        top: '50%',
        left: '50%',
    });
    $spinner.append($("<i class='fa fa-spinner fa-pulse'>"));

    // wrapper to capture the content to allow the button to retain its size
    var $contentWrapper = $("<div class='inline-loading-wrapper'>").css({
        color: 'transparent',
        opacity: 0,
    });

    // hack to allow translate to position the spinner in the middle without affecting the button's position attribute
    var $buttonWrapper = $("<div class='inline-loading-position-wrapper'>").css({
        position: 'relative',
    });

    return function ($el, options) {
        function addInlineSpinner($el) {
            $el.addClass('disabled');
            $el.wrapInner($contentWrapper);
            $el.append($spinner);
            $el.wrapInner($buttonWrapper);
        }

        function removeInlineSpinner($el) {
            var $contentWrapper = $el.find('.inline-loading-wrapper');

            if ($contentWrapper.length === 0) {
                // if the promise is resolved fast enough, the inline spinner may not even activate (see DELAY and SHOW_IMMEDIATELY)
                // in this case, just abort
                return;
            }

            var buttonContent = $contentWrapper[0].innerHTML;

            $el.removeClass('disabled');
            $el.find('.inline-loading-spinner').remove();
            $el.find('.inline-loading-position-wrapper').remove();
            $el.html(buttonContent);
        }

        options = _.extend(options || {}, {
            show: addInlineSpinner,
            hide: removeInlineSpinner,
        });

        return loading($el, options);
    };
})();
