'use strict';

/* globals require, module */
const $ = require('jquery'),
    _ = require('underscore'),
    Backbone = require('backbone');
Backbone.$ = $;
var gaw = require('../../common/google_analytics_wrapper');

// var bootstrap = require('bootstrap'); // not used directly, but through $el.modal WARNING: adding this will allow modal tests to run in isolation but will screw up the appointment book badly. modals will pop up randomly etc

/**
 * Abstract class for use by view's that are bootstrap modals. The template should contain the entire modal html.
 *
 * Intended to be created and destroyed in each show/hide life cycle.
 *
 * Note: Don't use data attributes for modal close on elements that also have bound backbone events,
 * instead call this.close() after performing the rest of the event functionality.
 *
 * Optional configuration:
 *      Force responsive viewport: To force a responsive view port when the modal is opened on a no response page
 *      set a forceResponsiveViewPort member variable to true
 *
 *      Make the modal have a url hash fragment: To set the modal up with a url set a closeReturnLocationHash member variable
 *      to the uri you want to return to when the modal is closed. Setting up the specific modals uri is still the
 *      responsibility of a router or other functionality. What is handled is returning the to the closeReturnLocationHash on
 *      hash change and modal close.
 *
 *      A Backbone hide event is triggered when the model is closed. This can be used like:
 *
 *          modal.on('hide', function() {
 *              console.log('I'm hidden')
 *          });
 *
 */
module.exports = Backbone.View.extend({
    className: 'modal cFade useLoadingFeedback',
    forceResponsiveViewPort: false,
    originalViewPort: null,
    closeReturnLocationHash: null,
    beforeClose: null,
    modalOptions: {}, //standard bootstrap modal options such as: backdrop: 'static'

    // result promise and "private" deferred
    _result: null,

    // members to assist communication from .close to tearDown
    resultValue: null,
    success: false,

    /**
     * Specify a google analytics action to automatically track the different types of modal closes (can't do escape though, well not easily)
     */
    gaCloseAction: null,
    gaCategory: null,

    baseEvents: {
        'click .js-header-close-button': 'onHeaderCloseButtonClick',
        'click .js-cancel-button': 'onCancelButtonClick',
        mousedown: '_onBackgroundClick',
    },
    beforeHide: () => true,

    show: function () {
        this.render();
        this.inject();

        this.events = _.extend(this.events || {}, this.baseEvents);
        this.delegateEvents();

        var $el = this.getModalElement();

        this.handleCloseReturnUrl();
        this.forwardModalEvents();
        $el.on('shown.bs.modal', this.setUpModal.bind(this));
        $el.modal(this.modalOptions);
        $el.on('hidden.bs.modal', this.tearDown.bind(this));
        $el.on('hide.bs.modal', this.beforeHide.bind(this));
        return this;
    },

    // hide the modal without resolving it, i.e. if you want to have another level of modals
    // if you are finished with he modal, use "close" or "dismiss"
    // show it again with "unhide"
    hide: function () {
        this.$el.css({
            display: 'none',
        });
    },

    // bring the modal back to focus from "hide" state
    unhide: function () {
        this.$el.css({
            display: 'block',
        });
    },

    // if the named hook is a defined function, call it with given arguments, otherwise no-op
    runHook: function (context, hookName, argArray) {
        if (typeof context[hookName] === 'function') {
            return context[hookName].apply(context, argArray);
        }
    },

    getPromise: function () {
        // i made the mistake of calling this method 'promise', but this changes how this object behaves in a promise callback chain!
        if (this._result === null) {
            this._result = $.Deferred();
        }

        return this._result.promise();
    },

    /**
     * Alternative to getPromise that is more convenient for async await programming
     * The modal.js design at the moment will reject the promise if the user cancels
     * the modal interaction, which will cause "Uncaught in promise" JS error logs
     * if not suppressed.
     *
     * A default catch handler can be automatically attached with ignoreDismissed
     *
     * @param {boolean} ignoreDismiss If true, attach a default catch handler to suppress "canceled" errors
     * @return {Promise}
     */
    getNativePromise: function (ignoreDismiss) {
        const out = Promise.resolve(this.getPromise());

        if (ignoreDismiss) {
            // No-op catch to suppress uncaught rejection problems from modal cancel etc.
            out.catch(() => console.info('Modal canceled'));
        }

        return out;
    },

    forwardModalEvents: function () {
        var $el = this.getModalElement();
        var that = this;

        ['hide', 'show', 'shown', 'hidden'].forEach(function (prefix) {
            $el.on(prefix + '.bs.modal', that.trigger.bind(that, prefix));
        });
    },

    // close modal, resolving promise successfully with given resultValue
    close: function (resultValue) {
        this.success = true;
        this.resultValue = resultValue;
        this.$el.modal('hide');
    },

    // dismiss modal, rejecting promise with given resultValue
    // note that clicking the 'x' or outside the modal etc will also reject the promise
    dismiss: function (resultValue) {
        this.success = false;
        this.resultValue = resultValue;
        this.$el.modal('hide');
    },

    inject: function () {
        $('body').append(this.$el);
    },

    handleCloseReturnUrl: function () {
        if (this.closeReturnLocationHash) {
            this.closeOnHashChange();
        }
    },

    closeOnHashChange: function () {
        window.addEventListener('hashchange', this.close.bind(this), { once: true });
    },

    setUpModal: function () {
        this.handleResponsiveViewPort();

        this.handleAutofocus();

        this.runHook(this, 'onShown');
    },

    handleAutofocus: function () {
        var $el = this.$el;

        $el.find('[autofocus]').focus();
    },

    handleResponsiveViewPort: function () {
        if (this.forceResponsiveViewPort) {
            this.instateResponsiveViewPort();
        }
    },

    getModalElement: function () {
        return this.$el;
    },

    tearDown: function () {
        var returnHash = _.result(this, 'closeReturnLocationHash', false);

        if (typeof this.beforeClose === 'function') {
            this.beforeClose();
        }

        if (this.forceResponsiveViewPort) {
            this.reinstateOriginalViewPort();
        }

        if (returnHash) {
            //KIT-25835
            //this might be a problem for appt book, applying a hash directly like this will not work
            //for react router
            window.location.hash = returnHash;
        }

        this.undelegateEvents();
        this.stopListening();
        this.$el.removeData().unbind().remove();

        // if we used it,
        if (this._result !== null) {
            // resolve modal promise
            if (this.success) {
                this._result.resolveWith(this, [_.result(this, 'resultValue')]);
            } else {
                this._result.rejectWith(this, [_.result(this, 'resultValue')]);
            }
        }
    },

    instateResponsiveViewPort: function () {
        var responsiveViewPort = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
        var $viewPort = this.$viewPort();
        this.originalViewPort = $viewPort.attr('content');

        if (responsiveViewPort !== this.originalViewPort) {
            $viewPort.attr('content', responsiveViewPort);
        }
    },

    reinstateOriginalViewPort: function () {
        var $viewPort = this.$viewPort();

        if (this.originalViewPort && this.originalViewPort !== $viewPort.attr('content')) {
            $viewPort.attr('content', this.originalViewPort);
        }
    },

    $viewPort: function () {
        return $('[name=viewport]');
    },

    _onBackgroundClick: function (e) {
        // If we didn't actually click the background just early exit.
        if (e.target !== this.el) {
            return; // Do not return false, we don't want to block all click events in modals.
        }

        return this.onBackgroundClick(e);
    },

    onBackgroundClick: function (event) {
        if (this.abortIfRequested(event)) {
            return;
        }

        if (this.closesOnBackgroundClick()) {
            if (this.gaCloseAction) {
                this.gaTrackCloseFromBackgroundClick();
            }
            this.runHook(this, 'onCloseAction', [event]);
        }
    },

    closesOnBackgroundClick: function () {
        // return this.$('.modal-dialog').data('backdrop') !== 'static'; // Our markup is wrong, so we can't use this.
        return this.modalOptions && this.modalOptions.backdrop !== 'static';
    },

    onCancelButtonClick: function (event) {
        if (this.abortIfRequested(event)) {
            return;
        }

        if (this.gaCloseAction) {
            this.gaTrackCloseFromCancelButton();
        }
        this.runHook(this, 'onCloseAction', [event]);
    },

    onHeaderCloseButtonClick: function (event) {
        if (this.abortIfRequested(event)) {
            return;
        }

        if (this.gaCloseAction) {
            this.gaTrackCloseFromHeaderCross();
        }

        this.runHook(this, 'onCloseAction', [event]);
    },

    gaTrackCloseFromBackgroundClick: function () {
        this.gaTrackClose('clicking outside modal');
    },

    gaTrackCloseFromCancelButton: function () {
        var label = this.$('.js-cancel-button:not(.disabled)').text().toLowerCase();
        this.gaTrackClose('using ' + label + ' button');
    },

    gaTrackCloseFromHeaderCross: function () {
        this.gaTrackClose('using header X');
    },

    gaTrackClose: function (label) {
        gaw.trackEvent(this.gaCategory, _.result(this, 'gaCloseAction'), label);
    },

    /**
     * Optional overridable hook invoked when the modal is closed via the 'x', cancel button
     * or background click (if enabled), where the default handlers are used. If the handlers
     * are being overridden, will need to be called manually. (e.g. see onHeaderCloseButtonClick)
     */
    onCloseAction: function () {
        // no op by default
    },

    /**
     * Invoked by close action handlers to check the onRequestClose callback to
     * determine if closing should proceed, if not, block the close event
     *
     * @param event
     * @returns {boolean}
     */
    abortIfRequested: function (event) {
        const requestClose = this.onRequestClose?.call(this, event);

        if (requestClose === false) {
            // Abort the click, prevent close
            event.stopImmediatePropagation();
            event.preventDefault();
            return true;
        }

        return false;
    },

    /**
     * Overridable hook to determine whether closing should proceed, called
     * when 'x', cancel button or background is clicked.
     *
     * e.g. unsaved changes check
     *
     * @returns {boolean} True if closing should proceed, false to cancel
     */
    onRequestClose: function () {
        // Always close when user clicks something that closes the modal
        return true;
    },
});
