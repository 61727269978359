'use strict';

require('src/common/CommonPage.js');
require('src/css/k1/logged_out.scss');

var $ = require('jquery');
var _ = require('underscore');
global.$ = window.$ = window.jquery = window.jQuery = $;
require('src/bespoke/responsive');
require('src/common/commonEventListener');

var modal_helper = require('./../ui-components/modals');

require('./../common/responsive');

require('./../custom_jquery_plugins/jquery.url.js');
var RedirectView = require('./../backbone/views/redirectView.js');

function set_pass_first_submit() {
    var password = $('#new_password').val(),
        confirm_password = $('#confirm_password').val(),
        teesandsees = $('#teesandsees').prop('checked');
    teesandsees = teesandsees === true ? 1 : 0;

    $('#newPassLengthWarning').hide();

    if ($('#new_password').val().length < 8) {
        $('#newPassLengthWarning').show();
        return false;
    }

    $.post(
        '/k1/auth/set_firsttime_password/',
        {
            password: password,
            confirm_password: confirm_password,
            teesandsees: teesandsees,
        },
        function (data) {
            //replace dialog html content with return data's html content only
            $('#first_login').html($(data.body).html());
            return false;
        },
        'json'
    );
}

$(document).ready(function () {
    var $redirect = $('#login_form input[name=redirect]');
    if ($redirect.length > 0) {
        $redirect.val($redirect.val() + window.location.hash);
    }

    //login
    $(document).on('keydown', '#login_form #password,#login_form #login', function (event) {
        if (event.keyCode === '13' && $('input[name=password]').val() !== '') {
            $('#login_form').submit();
        }
    });

    $(document).on('submit', '#login_form', function () {
        var loginPostData,
            login = $('input[name=login]').val(),
            password = $('input[name=password]').val(),
            remember = $('input[name=remember]').prop('checked'),
            $redirect = $('input[name=redirect]'),
            return_to = $.url.param('return_to'),
            sso = $.url.param('sso'); //say if we are trying to do a single sign on

        $('#login_form_submit, .loginErrs').hide();
        $('#loginLoader').show();

        //convert bool to int
        remember = remember === true ? 1 : 0;

        //TODO: need to see if there is a return_to url from zendesk, and if so pass that to the ajax request
        loginPostData = {
            login: login,
            password: password,
            remember: remember,
            return_to: return_to,
            sso: sso,
        };
        if ($redirect.length > 0) {
            loginPostData.redirect = $redirect.val();
        }
        function loginRequest(loginBaseUrl, postData, retry) {
            $.ajax({
                type: 'POST',
                url: loginBaseUrl + 'k1/auth/login',
                data: postData,
                dataType: 'json',
                xhrFields: { withCredentials: true },
            }).done(function (data) {
                if (data.status === 'success') {
                    console.log('post success');
                    window.location = loginBaseUrl + 'k1/';
                    return false;
                } else if (data.status === 'redirect' && data.redirectMethod === 'POST') {
                    doPostRedirect(data);
                    return true;
                } else if (data.status === 'redirect') {
                    console.log('foreign login redirect to ' + data.content);
                    if (data.content.toLowerCase().indexOf('error') < 0) {
                        window.location = data.content;
                    } else {
                        $('#loginContentWrapper').html(data.content).show();
                    }
                    return false;
                } else if (retry && data.status === 'retry') {
                    loginRequest(data.content, postData, false); //only want to retry once
                    return false;
                }

                //not success, redirect or retry so show unable to login
                $('.loginErrs').slideDown('fast');
                $('#login_form_submit').show();
                $('#loginLoader').hide();

                return false;
            });
        }

        loginRequest('/', loginPostData, true);

        return false;

        function doPostRedirect(data) {
            new RedirectView({
                url: data.content,
                parameter_map: {
                    autoLogin: $('#login').val(),
                    autoPassword: $('#password').val(),
                },
            }).doRedirect();
        }
    });

    //if set password add dialogue to modal
    $(document).on('click', '#set_pass_first_submit', function (e) {
        e.preventDefault();
        set_pass_first_submit();
    });

    //new user flow
    modal_helper.showModals();
});
