/**
 * Include this file in new entry_points
 *
 */

var $ = require('jquery');
global.$ = window.$ = window.jquery = window.jQuery = $;

require('./../ui-components/navbar');
require('./../common/global_ajax_error_handler');
require('./../common/standard_handlers');
