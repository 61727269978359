var $ = require('jquery'),
    feedback = require('./../ui-components/feedback-bar');

module.exports = (function () {
    var out = {};

    out.defaultHandler = function (event, jqxhr, settings, thrownError) {
        //test to see if error is generated by user navigating away from the page
        //if (parseInt(xmlHttpRequest.status,10) !== 0) {

        var handled = typeof jqxhr.handled !== 'undefined' && Boolean(jqxhr.handled);

        if (!handled && jqxhr.status === 401) {
            window.location = '/k1/not_logged_in';
            return;
        }

        var navigating_away = parseInt(jqxhr.status, 10) === 0;

        if (!handled && !navigating_away) {
            feedback.showDefaultError(jqxhr, settings);
        }
    };

    $(document).ready(function () {
        $(document).ajaxError(out.defaultHandler);
    });

    return out;
})();

/**
 * AJAX defaults
 */

/*


boot.setAjaxDefaults = function () {
    //   console.log("AJAX defaults loaded.");

    util.setErrorController({
        accept: function (event, xhr, settings, errorThrown) {

            switch (xhr.status) {
                case 401:
                    window.location = '/k1/not_logged_in'; // Should this call util.sessionTimeout instead?

                    break;

                default:
                    if (xhr.statusText !== 'abort') {

                        var logger = function () {
                            // console functions don't like being invoked without console
                            (typeof console.error === 'function' ? console.error : console.log).apply(console, arguments);

                            
                        };

                        util.ajax.showError(xhr, xhr.statusText, errorThrown);

                        var details = xhr.responseText;

                        try {
                            // Scrub data for logging
                            var payload = JSON.parse(xhr.responseText);
                            if (typeof payload.detail !== 'undefined') {
                                payload.detail = undefined;
                            }

                            details = JSON.stringify(payload);
                        } catch (e) {
                            // Not JSON
                        }
                        logger("AJAX error: " + errorThrown + "; " + details + "; Global callback.");
                    }
                    break;
            }

        }
    });

    $(document).ajaxError(function (event, xhr, settings, errorThrown) {

        if (typeof settings.error !== 'function') {
            // Delegate
            var controller = util.getErrorController();

            if (controller && typeof controller.accept === 'function') {
                return controller.accept(event, xhr, settings, errorThrown);
            }
        }

        return true;
    });
};
    */
