'use strict';
var $ = require('jquery'),
    modal,
    defaultTitle,
    defaultMessage,
    intercom = require('./../common/intercom'),
    Rollbar = require('./../common/rollbar'),
    title,
    message, // Yellow feedback bar events
    Brand = require('./../common/brand');
var websiteVersion = null;

/**
 *
 * All errors should go through this class.
 * This should also track them in our various analytics tools
 *
 * Currently needs to work with responsive and non resposnive pages
 *
 *
 */

var getWebsiteVersion = function () {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', window.location.origin + '/VERSION?_=' + new Date().getTime(), false);
    xmlHttp.send(null);
    return xmlHttp.responseText;
};

$(document).ready(function () {
    modal = $('#badErrorModal');

    defaultMessage =
        'Something went wrong. Please try refreshing the page in your browser.<br>' +
        'If the problem persists please contact ' +
        Brand.name +
        ' support.';

    if (modal.length) {
        title = modal.find('.modal-title');
        defaultTitle = title.html();
    } else {
        defaultTitle = 'Error';
    }

    if (window.location.hostname !== 'www.dev.kitomba.com' && window.location.hostname !== 'localhost') {
        websiteVersion = getWebsiteVersion();
    }
});

/**
 * todo remove after responsive is complete
 * lol
 *
 * @deprecated
 * @param message
 * @param templateData
 */
function legacyShow(message, templateData) {
    //its possible that the message may have underscore template data in it
    var template =
        "<span id='topNoteMsg' style='line-height: initial'>" +
        message +
        '<br>' +
        "<div style='margin-top: 8px'><a class='' style='padding-right: 20px' href='javascript:location.reload();'>Refresh</a>" +
        "<a class='' style='padding-left: 20px' href=\"javascript:$('body').trigger('logout', 'clicked-logout');\">Log out</a></div>" +
        '</span>';

    var html = _.template(template, templateData);

    $('#topNote').html(html).slideDown();
}

function showOutOfDateMessage(templateData, jqxhr, requestSettings) {
    if (websiteVersion === null) {
        return false;
    }

    var currentVersion = getWebsiteVersion();
    if (currentVersion === websiteVersion) {
        return false;
    }

    var message =
        'A new version of ' +
        Brand.product_name +
        ' is available.<br>' +
        'Please refresh this page in your browser to get the latest version.<br>';
    showYellowBar(defaultTitle, message, jqxhr, requestSettings, templateData, false);

    return true;
}

// not for general feedback, use for crashes (error sent to rollbar)
function showMessage(message, title, templateData, jqxhr, requestSettings) {
    if (showOutOfDateMessage(templateData, jqxhr, requestSettings)) {
        return;
    }

    showYellowBar(title, message, jqxhr, requestSettings, templateData, true);
}

function showYellowBar(title, message, jqxhr, requestSettings, templateData, isError) {
    if (!templateData) {
        templateData = {};
    }

    var requestId = null;
    var errorUrl = null;
    if (_.isObject(jqxhr)) {
        var errorResponse = jqxhr.responseJSON;

        requestId = _.isObject(errorResponse) && errorResponse.unique_id ? errorResponse.unique_id : null;
    }

    if (_.isObject(requestSettings)) {
        errorUrl = requestSettings.url ? requestSettings.url : null;
    }

    if (isError) {
        intercom.event('recieved-yellow-errorbar', { message: message });
        // maybe this rollbar should be moved out to global error handler, then we can use this function for displaying any message
        Rollbar.error(new Error('Received yellow bar error'), { unique_id: requestId, error_url: errorUrl });
    } else {
        intercom.event('recieved-yellow-outofdate', { message: message });
        // maybe this rollbar should be moved out to global error handler, then we can use this function for displaying any message
        Rollbar.error(new Error('Received yellow bar out of date'), {
            unique_id: requestId,
            error_url: errorUrl,
        });
    }

    // use the responsive 'Uh oh' dialog if it is present in the DOM (see views/k1/common/footer.php)
    if (modal.length) {
        if (typeof title === 'undefined' || !title) {
            title = defaultTitle;
        }

        if (typeof message === 'undefined' || !message) {
            message = defaultMessage;
        }

        // For out of date error
        if (!isError) {
            title = 'New version available';
            modal.find('.modal-header-warning').addClass('modal-header-outofdate');
        } else {
            // Set back to yellow if already changed to green
            modal.find('.modal-header-warning').removeClass('modal-header-outofdate');
        }

        modal.find('.modal-title').html(title);
        modal.find('.modal-body').html(message);

        modal.modal('show');
    } else {
        legacyShow(message, templateData);
    }
}

function showDefaultErrorMessage(jqxhr, requestSettings) {
    showMessage(defaultMessage, defaultTitle, null, jqxhr, requestSettings);
}

function hideMessage() {
    modal.modal('hide');

    //todo remove after responsive is completed
    $('#topNote').hide();
}

module.exports.show = showMessage;
module.exports.showDefaultError = showDefaultErrorMessage;
module.exports.hide = hideMessage;
