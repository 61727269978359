import React from 'react';
import Handlebars from 'handlebars';

const hbr = `
<form id="{{form_id}}" action="{{url}}" method="{{method}}" style="display:none">
    {{#each parameters}}
        {{#if isArray}}
        {{#each value}}
        <input type="text" name="{{../key}}[]" value="{{this}}">
        {{/each}}
        {{else}}
        <input type="text" name="{{key}}" value="{{value}}">
        {{/if}}
    {{/each}}
</form>


`.trim();
let template = false;
export const getTemplate = () => {
    return Handlebars.compile(hbr);
};
export default (props) => {
    template = template ? template : getTemplate();
    return template(props);
};
