'use strict';
var $ = require('jquery');

require('fancybox');
require('./../common/fancybox.defaults');
require('jquery-migrate');
require('blockui');

/* CONSTANTS */
var processing = false;
var modal_normal_width = '556px';
var modal_narrow_width = '260px';
var modal_facebook_width = '750px';

function resetAnnoyingModalPlugin() {
    $('.fancybox-wrap').stop(true, true);
    $('#fancybox-overlay').stop(true, true);
}

function nextModal(modalHref, nextHref) {
    var $nextModal = $(modalHref).find('.nextModalLink');

    if (nextHref === undefined) {
        $nextModal.removeData('next-modal');
    } else {
        $nextModal.data('next-modal', nextHref);
    }
}

function showModalUtil(situationHref, cleanupCallback) {
    if ($(situationHref).hasClass('modal')) {
        $.fancybox.close();
        $(situationHref).modal('show');
    } else {
        var opts = {
            transitionIn: 'none',
            transitionOut: 'none',
            padding: 0,
            hideOnOverlayClick: false,
            enableEscapeButton: false,
            showCloseButton: true,
            type: 'inline',
            href: situationHref,
        };

        if (typeof cleanupCallback === 'function') {
            opts.afterClose = cleanupCallback;
        }

        $.fancybox(opts);
    }
}

function hideModalLoadingFeedback() {
    $.unblockUI();
    processing = false;
}

//functions to show/hide modal loading feedback - use when modals can be slow to open due to ajax etc
function showModalLoadingFeedback() {
    if (processing === true) {
        return;
    }

    $.blockUI({
        //this has been set to 0 so that there is no blink when transitioning from a modal (with overlay) to loading
        fadeIn: 0,
        message: "<img src='/images/k1/loader-bbb-000.gif'>",
        css: {
            padding: 0,
            margin: 0,
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'default',
        },
        overlayCSS: {
            backgroundColor: '#555',
            opacity: 0.4,
            cursor: 'default',
        },
        onBlock: function () {
            // processing = true;
        },
        onUnblock: function () {
            //processing = false;
        },
    });
    processing = true;
}

function showModals() {
    //show the modal if it exists, only one modal should have been initialised
    // this for showing the first time password setup modal
    $('#first_login').modal('show');
    //this is used for the activation successful activation code is no longer valid modal
    $('#custom_modal').modal('show');
}

$(document).ready(function () {
    //generic close modal
    $(document).on('click', '.closeModalLink', function () {
        $.fancybox.close();
        $('.custom_modal').hide();
    });
    $(document).on('click', '.closeModal', function () {
        $.fancybox.close();
        $('.custom_modal').hide();
    });

    $(document).on('click', '.nextModalLink', function (e) {
        var $e = $(e.target),
            next = $e.data('next-modal');

        $e.removeData('next-modal');

        if (typeof next === 'undefined') {
            $.fancybox.close();
        } else {
            showModalUtil(next);
        }
    });

    //The following code allows stackable modals, plus makes long ones scroll nicely.
    //From A1rPun's answer here:
    //http://stackoverflow.com/questions/19305821/multiple-modals-overlay

    /*var zIndex = Math.max.apply(null, Array.prototype.map.call(document.querySelectorAll('*'), function(el) {
      return +el.style.zIndex;
    })) + 10;*/

    $(document).on('show.bs.modal', '.modal', function () {
        var zIndex = 1040 + 10 * $('.modal:visible').length;
        $(this).css('z-index', zIndex);
        setTimeout(function () {
            $('.modal-backdrop')
                .not('.modal-stack')
                .css('z-index', zIndex - 1)
                .addClass('modal-stack');
        }, 0);
    });

    $(document).on('show.bs.modal', '.modal', function () {
        var $this = $(this);
        var customModalSizes = ['modal-xl'];

        //adds custom container classes where our custom size classes are found, so devs don't have to remember to add both
        customModalSizes.forEach(function (customClass) {
            if ($this.children().first().hasClass(customClass)) {
                $this.addClass(customClass + '-container');
            }
        });
    });

    //It seems like maybe hidden.bs.modal is not triggered on modals with 'fade' class
    //using a custom class / animation cFade (custom fade) instead of 'fade'.

    $(document).on('hidden.bs.modal', '.modal', function () {
        if ($('.modal:visible').length !== 0) {
            $(document.body).addClass('modal-open');
        } else {
            $(document.body).removeClass('modal-open');
        }
    });
});

module.exports.showModals = showModals;
module.exports.hideModalLoadingFeedback = hideModalLoadingFeedback;
module.exports.showModalLoadingFeedback = showModalLoadingFeedback;
